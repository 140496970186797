<template>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {getToken, query} from "@/utils";
import config from "@/config";

export default defineComponent({
  async created() {
    if (getToken()) {
      let res = await query(config.api_url + "link_lists?cookie=" + getToken(), "GET", null, {}, false);
      if (res.links.length > 0) {
        this.$router.push('/links');
        return;
      }
    }
    this.$router.push('/entry');
  }
})
</script>