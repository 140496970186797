import { createRouter, createWebHistory } from 'vue-router'
import Entry from '../views/Entry.vue'
import Get from '../views/Get.vue'
import Have from '../views/Have.vue'
import Links from '../views/Links.vue'
import Loading from '../views/Loading.vue'
import {getToken, query} from "@/utils";
import config from "@/config";

const routes = [
  {
    path: '/',
    name: 'Loading',
    component: Loading,
    meta: {requiresAuth: false}
  },
  {
    path: '/entry',
    name: 'Entry',
    component: Entry
  },
  {
    path: '/links',
    name: 'Links',
    component: Links,
    meta: {requiresAuth: false}
  },
  {
    path: '/code',
    name: 'Code',
    component: Have
  },
  {
    path: '/email/:email?',
    name: 'Email',
    component: Get,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    component: Loading,
    meta: {requiresAuth: false}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (route) => {
  if (route.meta.requiresAuth === false)
    return true;

  if (getToken()) {
    let res = await query(config.api_url + "link_lists?cookie=" + getToken(), "GET", null, {}, false);
    if (res.links.length > 0) {
      return '/links';
    }
  }
  return true;
});

export default router
